@import "./libs/assets/colors";
@import "./libs/assets/sizes";
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "./libs/assets/base-theme";

body {
  background-color: $background;
}

button {
  touch-action: manipulation;
}

.content {
  width: 900px;
  max-width: 100%;
  padding: 20px 20px 100px 20px;
  margin: auto;
  color: $table-font-color;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  box-sizing: border-box;
}

h1 {
  color: $title-color;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}

h2 {
  color: $title-color;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
}

h4 {
  color: $table-font-color;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.divider {
  background-color: $gray-light-2;
  height: 1px;
  margin: 20px 0;
}

.counter-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $gray-light-2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.block-btn {
  width: 100%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

button[disabled] {
  opacity: 0.6;
  pointer-events: none;
  border-color: $gray-light-3;
  color: $gray-light-3;
}

@media (max-width: 767px) {
  .page-title {
    &.has-back {
      align-items: start !important;
      padding-left: 60px;

      h1,
      p {
        text-align: left;
        min-width: 100%;
      }

      .primary-btn {
        &.back-button {
          padding: 20px 10px;
          top: 0;
        }
      }
    }
  }
}

.primary-btn {
  cursor: pointer;
  border-radius: 4px;
  padding: 15px 30px;
  color: $primary;
  border: 1px solid $primary;
  font-size: 16px;
  background-color: $white;
  text-align: center;
  text-decoration: none;

  &.warn-btn {
    color: $error;
    border: 1px solid $error;
  }
  &[disabled] {
    opacity: 0.4;
  }
}

.dark-theme {
  .primary-btn:not(.filled-btn) {
    color: $table-font-color;
    &:hover {
      background-color: $gray-light-1;
    }
  }
}

.filled-btn {
  background-color: $primary;
  color: #ffffff;

  &.warn-btn {
    background-color: $error;
    border: 1px solid $error;
    color: #ffffff;
  }

  &.success {
    &,
    &:hover,
    &:active,
    &.active {
      background-color: $success;
      border: 1px solid $success;
    }
  }
}

.page-title {
  position: relative;

  .primary-btn {
    position: absolute;
    left: 0;
  }

  h1 {
    color: $title-color;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  }

  p {
    max-width: 60%;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: $title-color;
    text-align: center;
  }

  .back-button {
    border: 1px solid $blue-gray;
    margin-right: 20px;
  }
}

.resupply-footer {
  margin-top: 80px;

  img {
    width: 300px;
    max-width: 60%;
  }
}

form {
  width: 100%;
}

.form-row {
  margin-bottom: 20px;
  position: relative;

  .placeholder {
    color: #c0c0c0;
    margin: 0;
    position: absolute;
    z-index: -1;
    font-size: 14px;
    top: 35px;
    left: 14px;

    span {
      color: #7f7f7f;
    }
  }

  &.error {
    .placeholder {
      &,
      span {
        color: $error;
      }
    }
  }

  .placeholder + textarea {
    background-color: transparent;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="tel"],
  input[type="password"],
  input[type="email"],
  textarea {
    appearance: none;
    transition: border-color 0.25s ease-in-out 0s,
      box-shadow 0.1s ease-in-out 0s, background-color 0.25s ease-in-out 0s,
      color 0.25s ease-in-out 0s;
    border: 1px solid rgb(216, 220, 222);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    font-family: inherit;
    padding: 0.714286em 0.857143em;
    min-height: 42px;
    font-size: 16px;
    line-height: 1.28571;
    background-color: $white;
    color: $table-font-color;
    outline: 0 !important;

    &:focus {
      border-color: $blue-gray;
    }
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-size: 16px !important;
  }

  input:-webkit-autofill::first-line {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif !important;
    font-size: 16px !important;
    line-height: 1.28571 !important;
    padding: 0.714286em 0.857143em !important;
    outline-offset: -2px !important;
  }
}

.charity-info {
  img {
    max-width: 280px;
    margin: 20px;
  }

  h1 {
    color: $title-color;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 20px;
  cursor: pointer;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0;
  transform: rotate(-135deg);
}

.outcome-wrapper {
  height: 90vh;
  text-align: center;
}

.hidden-xs {
  @media screen and (max-width: $smMax) {
    display: none;
  }
}

.hidden {
  display: none;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.screen-title {
  p {
    margin: 0;
    font-size: 14px;
  }

  h1 {
    font-size: 24px;
    line-height: 100%;
  }

  &.image {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding-right: 40px;

    img {
      max-height: 100px;
    }
  }
}

.screen-content {
  margin-top: 30px;
}

/* Border */
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $blue-gray !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $blue-gray !important;
}

.mat-button-toggle-checked .mat-button-toggle-button {
  background-color: $primary !important;
  color: white !important;
}

.no-padding-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-progress-bar-buffer {
  background-color: $gray-light-2;
}

.mat-progress-bar-fill::after {
  background-color: $blue-gray;
}

.all-caps {
  text-transform: uppercase;
}

.card {
  margin: 30px 0;
  border: 1px solid $gray-light-2;
  padding: 15px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $blue-gray !important;
}

.text-capitalized {
  text-transform: capitalize;
}

snack-bar-container.success {
  background: $success;

  .mat-simple-snackbar,
  .mat-button-wrapper {
    color: #ffffff;
  }
}

.mat-checkbox {
  .mat-checkbox-inner-container {
    height: 24px !important;
    width: 24px !important;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $primary !important;
    }
  }
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary {
  background-color: $primary;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $primary;
}

@media screen and (max-width: 767px) {
  .mat-snack-bar-handset {
    margin-bottom: 60px !important;
  }
}

span.no-card {
  display: block;
  text-align: center;
  margin-top: 10px;
  background-color: $error;
  color: white;
  padding: 4px 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
