body {
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --black: #000;
  --black-rgb: 0, 0, 0;
  --main: rgb(31, 115, 183);
  --primary: rgb(31, 115, 183);
  --primary-rgb: 31, 115, 183;
  --primary-darken-10: rgb(27, 103, 164);
  --primary-darken-5: rgb(29, 109, 173);
  --success: #038153;
  --success-lighten: #26a275;
  --success-darken: #024f33;
  --background: #fafafa;
  --dark-bacground: #212121;
  --warn: #ffc107;
  --error: rgb(204, 51, 64);
  --error-darken: rgb(183, 45, 57);
  --blue: #42c0fb;
  --pink: #de5285;
  --gray-light-1: #f5f5f5;
  --gray-light-2: #dadada;
  --gray-light-3: #afafaf;
  --gray-dark-1: #888888;
  --gray-dark-2: #909090;
  --gray-dark-3: #777777;
  --blue-gray: rgb(60, 94, 150);
  --table-font-color: rgb(47, 57, 65);
  --text-on-primary: #ffffff;
  --title-color: rgb(44, 59, 100);
  --table-buttons-background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 12%,
    rgba(255, 255, 255, 0.8) 15%,
    white 100%
  );
}
body.dark-theme {
  --white: #333435;
  --white-rgb: 0,0,0;
  --black: #fff;
  --black-rgb: 255, 255, 255;
  --main: rgb(31, 115, 183);
  --primary: rgb(31, 115, 183);
  --primary-rgb: 31, 115, 183;
  --primary-darken-10: rgb(27, 103, 164);
  --primary-darken-5: rgb(29, 109, 173);
  --success: #038153;
  --success-lighten: #26a275;
  --success-darken: #024f33;
  --background: #1b1d1e;
  --dark-bacground: #212121;
  --warn: #ffc107;
  --error: rgb(204, 51, 64);
  --error-darken: rgb(183, 45, 57);
  --blue: #42c0fb;
  --pink: #de5285;
  --gray-light-1: #333435;
  --gray-light-2: #1c1c1c;
  --gray-light-3: #090909;
  --gray-dark-1: #d0d0d0;
  --gray-dark-2: #c7c7c7;
  --gray-dark-3: #b7b6b6;
  --blue-gray: rgb(60, 94, 150);
  --table-font-color: #fff;
  --text-on-primary: #ffffff;
  --title-color: rgb(204, 211, 229);
  --table-buttons-background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 12%,
    rgba(255, 255, 255, 0.8) 15%,
    white 100%
  );
}
